input::-ms-clear {
  display: none;
}

.course-viewer-container,
.document-viewer-container,
.presentation-viewer-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-viewer-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.course-viewer-container > iframe,
.document-viewer-container > iframe,
.presentation-viewer-container > iframe {
  width: 100%;
  height: 100%;
}

.pdf-viewer {
  height: inherit;
}

.pdf-viewer__page {
  margin-bottom: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.rh5v-DefaultPlayer_component {
  width: 100%;
  height: 100%;
}
